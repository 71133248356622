import React from 'react';

import { Link, LinkProps } from '@tanstack/react-router';

import { cn } from '@/lib/utils';

export type SidebarLinkProps = Omit<LinkProps, 'children'> & {
  children: React.ReactNode;
  className?: string;
};

export const SidebarLink = ({ children, className, ...props }: SidebarLinkProps) => {
  return (
    <Link
      className={cn(
        'flex h-[41px] items-center gap-[12px] rounded-md px-8 py-[10px] text-md text-primary-darker hover:bg-primary-darker/10 [&_svg]:size-6 [&_svg]:shrink-0 [&_svg]:text-primary'
      )}
      activeProps={{
        className: cn(
          'bg-primary-dark !text-primary-lighter hover:!bg-primary-dark/90 [&_svg]:!text-primary-lighter',
          className
        ),
      }}
      activeOptions={{
        exact: true,
        includeSearch: false,
      }}
      {...props}
    >
      {children}
    </Link>
  );
};
