import React from 'react';

import { Link, LinkProps } from '@tanstack/react-router';

import { cn } from '@/lib/utils';

export type NavbarLinkProps = Omit<LinkProps, 'children'> & {
  children: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  title?: string;
};

export const NavbarLink = ({ children, className, icon, ...props }: NavbarLinkProps) => {
  return (
    <Link
      className={cn(
        'flex h-[59px] w-1/4 items-center justify-center gap-[8px] rounded-sm py-1 text-md text-text-primary hover:bg-primary/10',
        className
      )}
      activeProps={{
        className: cn('bg-primary !text-primary-lighter hover:!bg-primary/90'),
      }}
      {...props}
    >
      {icon && (
        <span className="flex size-9 shrink-0 items-center justify-center rounded-md bg-primary-light/25 [&_svg]:size-6 [&_svg]:shrink-0 [&_svg]:text-primary-light">
          {icon}
        </span>
      )}
      <span className="hidden 2xl:block">{children}</span>
    </Link>
  );
};
