import { cn } from '@/lib/utils';

import { Card } from '../ui/card';
import { DashboardNav } from './dashboard-nav';

export type DashboardLayoutProps = React.ComponentProps<'div'> & {
  topContent?: React.ReactNode;
};

export const DashboardLayout = ({
  className,
  children,
  topContent,
  ...props
}: DashboardLayoutProps) => {
  return (
    <div className={cn('flex flex-1 flex-col gap-[30px]', className)} {...props}>
      <div className="flex flex-col gap-[25px]">
        {topContent}
        <Card className="rounded-md p-[5px]">
          <DashboardNav />
        </Card>
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
};
