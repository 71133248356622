import * as React from 'react';

import { cn } from '@/lib/utils';

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <section
      ref={ref}
      className={cn(
        'overflow-hidden rounded-[20px] bg-primary-lighter shadow-[0_10px_20px_-6px_rgba(0,0,0,0.20)]',
        className
      )}
      {...props}
    />
  )
);
Card.displayName = 'Card';

export { Card };
