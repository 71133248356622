import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { createCookies } from '@/features/auth/api/auth-cookies';
import { setCookie } from '@/utils/cookies';

//TODO: we should move that to env, but ATM its ok in the code
const firebaseConfig = {
  apiKey: 'AIzaSyABTlvmZzh-2qrMopP547rAb6tw09YKOcc',
  authDomain: 'realitiq-b6e0b.firebaseapp.com',
  projectId: 'realitiq-b6e0b',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export type FirebaseAuthResponse = {
  expires_in: string;
  token_type: string;
  refresh_token: string;
  id_token: string;
  user_id: string;
  project_id: string;
};

export async function refreshToken(refreshToken: string) {
  const response = await fetch(
    `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=refresh_token&refresh_token=${refreshToken}`,
    }
  );

  if (!response.ok) {
    throw new Error('Failed to refresh token');
  }

  const data = await response.json<FirebaseAuthResponse>();

  await createCookies(data.id_token);
  setCookie('rt', data.refresh_token);
}

export default app;
