import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

export const Route = createFileRoute(
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/spherical-images'
)({
  validateSearch: zodSearchValidator(
    z.object({
      view: fallback(z.enum(['all', 'gallery']), 'all'),
    })
  ),
});
