import * as React from 'react';

import { cn } from '@/lib/utils';

type SpinnerProps = React.SVGProps<SVGSVGElement>;

const Spinner = ({ className, ...props }: SpinnerProps) => (
  <svg
    className={cn('animate-spin', className)}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-label="Loading"
    role="status"
    fill="none"
    {...props}
  >
    {Array.from({ length: 12 }).map((_, index) => {
      const opacity = (index + 1) / 12;
      return (
        <rect
          key={index}
          x="11.5"
          y="2"
          width="1"
          height="4"
          rx="0.5"
          ry="0.5"
          fill="currentColor"
          opacity={opacity}
          transform={`rotate(${index * 30} 12 12)`}
        />
      );
    })}
  </svg>
);

export default Spinner;
