import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { ensureDocumentQueryData } from '@/features/estate-document/api/document';

export const Route = createFileRoute(
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/documents'
)({
  validateSearch: zodSearchValidator(
    z.object({
      order: fallback(z.enum(['asc', 'desc']), 'asc'),
    })
  ),
  loader: ensureDocumentQueryData,
});
