import { useMutation } from '@tanstack/react-query';

import { MutationConfig } from '@/lib/react-query';
import { api, extractData } from '@/lib/treaty';

type UploadDocumentOptions = {
  mutationConfig?: MutationConfig<typeof uploadDocument>;
};

type UploadDocumentType = {
  document: File;
  name?: string;
  estateId: string;
};

const uploadDocument = ({ document, name, estateId }: UploadDocumentType) => {
  return extractData(api.estates({ id: estateId }).documents.post({ name, document }));
};

export const useUploadDocument = ({ mutationConfig }: UploadDocumentOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: uploadDocument,
  });
};
