import { FC, MouseEventHandler, useEffect, useState } from 'react';

import { ChevronLeft, ChevronRight, FileTextIcon } from 'lucide-react';

import { env } from '@/constants/env';
import { ACCEPTED_MEDIA_TYPES } from '@/features/estate-media/schema/upload-media';

interface ImageCarouselProps {
  preselectedCurrentIndex?: number;
  images: {
    createdAt: string | null;
    mediaId: string | null;
    updatedAt: string | null;
    fileType: string | null;
  }[];
}

export const ImageCarousel: FC<ImageCarouselProps> = ({ images, preselectedCurrentIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    if (preselectedCurrentIndex) setCurrentIndex(preselectedCurrentIndex);
  }, [preselectedCurrentIndex]);

  return (
    <div className="relative mx-auto size-full overflow-hidden">
      <div
        className="flex h-full transition-transform duration-300 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image) => (
          <div key={image.mediaId} className="h-full min-w-full">
            {image?.fileType !== ACCEPTED_MEDIA_TYPES.Pdf ? (
              <img
                src={`${env.API_URL}/images/${image.mediaId}`}
                alt="carousel"
                className="size-full object-cover"
              />
            ) : (
              <div className="flex size-full items-center justify-center">
                <FileTextIcon viewBox="0 0 25 25" width={40} height={40} />
              </div>
            )}
          </div>
        ))}
      </div>
      <button
        onClick={prevImage}
        className="absolute left-4 top-1/2 -translate-y-1/2 rounded-full bg-white/50 p-2 text-white"
      >
        <ChevronLeft className="[&_*]:stroke-primary" />
      </button>
      <button
        onClick={nextImage}
        className="absolute right-4 top-1/2 -translate-y-1/2 rounded-full bg-white/50 p-2 text-white"
      >
        <ChevronRight className="[&_*]:stroke-primary" />
      </button>
    </div>
  );
};
