import { useMutation } from '@tanstack/react-query';

import { MutationConfig } from '@/lib/react-query';
import { api, extractData } from '@/lib/treaty';

type UseUpdateEstateThumbnail = {
  mutationConfig?: MutationConfig<typeof updateEstateThumbnail>;
};

export interface UpdateEstateThumbnail {
  estateId: string;
  imageId: string;
}

export const updateEstateThumbnail = ({ estateId, imageId }: UpdateEstateThumbnail) => {
  return extractData(api.estates({ id: estateId }).thumbnail.put({ imageId }));
};

export const useUpdateEstateThumbnail = ({ mutationConfig }: UseUpdateEstateThumbnail) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: updateEstateThumbnail,
  });
};
