import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { Estate } from '@/features/estates-property/api/estate';
import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

export type DigitalTwinsQueryOptions = {
  queryConfig?: QueryConfig<typeof getDigitalTwins>;
  id: Estate['id'];
};

export const getDigitalTwins = (id: Estate['id']) => {
  return extractData(api.estates({ id })['digital-twins'].get());
};

export const getDigitalTwinsOptions = (id: Estate['id']) => ({
  queryKey: ['digital-twins', id],
  queryFn: () => getDigitalTwins(id),
});

export const getDigitalTwinsQueryOptions = (id: Estate['id']) => {
  return queryOptions(getDigitalTwinsOptions(id));
};

export const ensureDigitalTwinsQueryData = ({ context: { queryClient }, params }: LoaderProps) => {
  return queryClient.ensureQueryData(getDigitalTwinsQueryOptions(params.estateId));
};

export const useDigitalTwins = ({ queryConfig, id }: DigitalTwinsQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getDigitalTwinsQueryOptions(id) });
};

export type DigitalTwinsResponseType = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.estates>['digital-twins']['get']>>['data']
>;
