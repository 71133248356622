import { z } from 'zod';

import { i18n } from '@/lib/i18n';

export const ACCEPTED_INPUT_TYPE = 'pdf';
export const ACCEPTED_FILE_TYPES = [`application/${ACCEPTED_INPUT_TYPE}`];

export const uploadDocumentSchema = () => {
  return z.object({
    file: z.instanceof(File).refine((file: File) => {
      return ACCEPTED_FILE_TYPES.includes(file?.type);
    }, i18n.t('pdf')),
    fileName: z.string(),
  });
};
