import { createFileRoute, Outlet } from '@tanstack/react-router';

import { MainLayout } from '@/components/layout/main-layout';

const Component = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export const Route = createFileRoute('/_authenticated/_sidebar-layout')({
  component: Component,
});
