import { createFileRoute, Outlet } from '@tanstack/react-router';

import { DashboardLayout } from '@/components/layout/dashboard-layout';

const Component = () => {
  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export const Route = createFileRoute(
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout'
)({
  component: Component,
});
