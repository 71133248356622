import { useQuery } from '@tanstack/react-query';

import { QueryConfig } from '@/lib/react-query';
import { api, extractData } from '@/lib/treaty';

export type AuthenticatedData = Awaited<ReturnType<typeof getAuthenticated>>;

type AuthenticatedQueryOptions = {
  queryConfig?: QueryConfig<typeof getAuthenticated>;
};

const getAuthenticated = () => {
  return extractData(api.authenticated.get());
};

export const getAuthenticatedQueryOptions = () => ({
  queryKey: ['authenticated'],
  queryFn: getAuthenticated,
});

export const useAuthenticated = ({ queryConfig }: AuthenticatedQueryOptions = {}) => {
  return useQuery({ ...getAuthenticatedQueryOptions(), ...queryConfig });
};
