import React, { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { Input, InputProps } from '@/components/ui/input';
import { env } from '@/constants/env';
import useClickOutside from '@/hooks/use-click-outside';
import { useDebouncedCallback } from '@/hooks/use-debounced-callback';

const { MAP_BOX_API_TOKEN } = env;

interface SuggestionResponse {
  id: string;
  type: string;
  place_type: string[];
  relevance: number;
  properties: {
    wikidata: string;
    category: string;
    landmark: boolean;
    address: string;
    foursquare: string;
    maki: string;
  };
  text: string;
  language: string;
  place_name: string;
  matching_text: string;
  matching_place_name: string;
  center: [number, number];
  geometry: { type: string; coordinates: [number, number] };
  context: {
    id: string;
    mapbox_id: string;
    wikidata?: string;
    short_code?: string;
    text: string;
    language?: string;
  }[];
}

interface Response {
  features: SuggestionResponse[];
}

interface SuggestionMapped extends SuggestionResponse {
  address?: string;
  latitude?: number;
  longitude?: number;
  countryId?: string;
  postalCode?: string;
  countryCode?: string;
}

export type OnSelectSuggestion = (data: SuggestionMapped) => void;

interface AddressAutocompleteProps extends InputProps {
  onSelectSuggestion: OnSelectSuggestion;
}

export const AddressAutocomplete: React.FC<AddressAutocompleteProps> = forwardRef<
  HTMLInputElement,
  AddressAutocompleteProps
>(({ onSelectSuggestion, value, ...inputProps }, ref) => {
  const [suggestions, setSuggestions] = useState<SuggestionMapped[]>([]);
  const { i18n } = useTranslation();

  const prevSelectedValue = useRef('');

  const clearSuggestions = () => setSuggestions([]);

  const fetchSuggestions = async (query: string) => {
    if (query.trim().length === 0) {
      return setSuggestions([]);
    }

    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      query
    )}.json?access_token=${MAP_BOX_API_TOKEN}&language=${i18n.language}`;

    const { data } = await axios.get<Response>(url);
    const mappedSuggestions = (data.features || []).map((feature) => {
      const postalCode = feature.context?.find((item) => item.id.startsWith('postcode'))?.text;
      const countryContext = feature.context?.find((item) => item.id.startsWith('country'));
      const cityContext = feature.context?.find((item: any) => item?.place_type?.includes('place'));
      const city = cityContext?.text;
      const countryId = countryContext?.short_code?.toUpperCase();
      const [longitude, latitude] = feature.center;
      const address = feature.place_name;
      return {
        ...feature,
        city,
        address,
        countryId,
        postalCode,
        latitude,
        longitude,
      };
    });
    setSuggestions(mappedSuggestions);
  };

  const debouncedFetchSuggestions = useDebouncedCallback(fetchSuggestions, 500);

  const containerRef = useClickOutside<HTMLUListElement>(clearSuggestions, !!suggestions.length);

  const handleSuggestionClick = (data: SuggestionMapped) => () => {
    prevSelectedValue.current = data.place_name;
    setSuggestions([]);
    return onSelectSuggestion(data);
  };

  React.useEffect(() => {
    if (value && value !== prevSelectedValue.current) {
      debouncedFetchSuggestions(value as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useLayoutEffect(() => {
    if (value) {
      prevSelectedValue.current = value?.toString();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full">
      <Input {...inputProps} ref={ref} value={value} />
      {suggestions.length > 0 && (
        <ul
          ref={containerRef}
          className="absolute z-50 mt-2 flex max-h-80 w-full flex-col gap-1 overflow-y-scroll rounded-md border border-gray-200 bg-white p-2 shadow-md"
        >
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.id}
              className="relative flex w-full cursor-pointer select-none items-center gap-2 rounded-sm border border-transparent px-2 py-1.5 text-md text-primary outline-none transition-colors hover:border-primary-light data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
              onClick={handleSuggestionClick(suggestion)}
            >
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});
