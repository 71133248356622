import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

type MeQueryOptions = {
  queryConfig?: QueryConfig<typeof getMeQueryOptions>;
};

export type MeUser = Awaited<ReturnType<typeof getMe>>;

export const getMe = () => {
  return extractData(api.users.me.get());
};

export const getMeQueryOptions = () => {
  return queryOptions({
    queryKey: ['users', 'me'],
    queryFn: getMe,
  });
};

export const ensureMeQueryData = ({ context: { queryClient } }: LoaderProps) => {
  return queryClient.ensureQueryData(getMeQueryOptions());
};

export const useMe = ({ queryConfig }: MeQueryOptions = {}) => {
  return useSuspenseQuery({ ...getMeQueryOptions(), ...queryConfig });
};
