import { useQuery } from '@tanstack/react-query';

import { api, extractData } from '@/lib/treaty';

export const getCountriesOptions = () => {
  return extractData(api.countries.get());
};

export const useCountriesOptions = () =>
  useQuery({
    queryKey: ['countries'],
    queryFn: getCountriesOptions,
  });
