import { RouterProvider as TanstackRouterProvider } from '@tanstack/react-router';

import { router } from '@/lib/router';

import { useAuth } from './use-auth';

export const RouterProvider = () => {
  const authenticated = useAuth();

  return <TanstackRouterProvider router={router} context={{ authenticated: authenticated.data }} />;
};
