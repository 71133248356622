import { useEffect } from 'react';

import { inMemoryPersistence, onAuthStateChanged } from 'firebase/auth';

import { useCreateAuthCookies } from '@/features/auth/api/auth-cookies';
import { useAuthenticated } from '@/features/auth/api/authenticated';
import { auth } from '@/lib/firebase';
import { setCookie } from '@/utils/cookies';

export function useAuth() {
  const { createAuthCookie } = useCreateAuthCookies();
  const authenticated = useAuthenticated();

  useEffect(() => {
    auth.setPersistence(inMemoryPersistence);

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        createAuthCookie.mutate(idToken);

        const refreshToken = currentUser.refreshToken;
        if (refreshToken) {
          setCookie('rt', refreshToken);
        }
      }
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Doesn't work without this empty dependency array, better solution very welcome

  return authenticated;
}
