import { createRouter } from '@tanstack/react-router';

import { AuthenticatedData } from '@/features/auth/api/authenticated';
import { queryClient } from '@/lib/react-query';
import { api } from '@/lib/treaty';
import { ErrorPage } from '@/pages/error';
import { LoadingPage } from '@/pages/loading';
import { routeTree } from '@/routeTree.gen';

export type RouterContext = {
  treaty: typeof api;
  queryClient: typeof queryClient;
  authenticated?: AuthenticatedData;
};

export type LoaderProps = {
  context: RouterContext;
  params: Record<string, string>;
};

export const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  defaultErrorComponent: ErrorPage,
  defaultPendingComponent: LoadingPage,
  context: {
    authenticated: undefined,
    queryClient,
    treaty: api,
  },
});
