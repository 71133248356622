import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

import Spinner from './spinner';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-sm text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:size-6 [&_svg]:shrink-0',
  {
    variants: {
      color: {
        primary:
          '[--button-color:hsl(var(--primary-color))] [--text-color:hsl(var(--primary-color-lighter))]',
        'primary-light':
          '[--button-color:hsl(var(--primary-color-light))] [--text-color:hsl(var(--primary-color-lighter))]',
      },
      variant: {
        contained: 'bg-[--button-color] text-[--text-color] hover:opacity-90',
        outline: 'border border-[--button-color] text-[--button-color] hover:opacity-80',
      },
      size: {
        sm: 'h-[36px] px-5 text-base [&_svg]:size-[20px]',
        md: 'h-[43px] px-5 text-base',
        lg: 'h-[49px] px-10 text-sm',
        xl: 'h-[57px] px-10 text-sm',
      },
    },
    defaultVariants: {
      color: 'primary-light',
      variant: 'contained',
      size: 'md',
    },
  }
);

export interface ButtonProps
  extends Omit<React.ComponentProps<'button'>, 'color'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  rounded?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      type = 'button',
      color,
      asChild = false,
      rounded = false,
      loading = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    const classes = cn(
      buttonVariants({ variant, size, className, color }),
      rounded && 'box-border flex aspect-square rounded-full p-0'
    );

    if (!asChild && loading) {
      return (
        <button ref={ref} type={type} className={classes} {...props}>
          <Spinner />
        </button>
      );
    }

    return <Comp ref={ref} type={type} className={classes} {...props} />;
  }
);

Button.displayName = 'Button';

export { Button, buttonVariants };
