import * as Sentry from '@sentry/react';

import { router } from '@/lib/router';

Sentry.init({
  dsn: 'https://0e01ef06ff7770d7df39c2fad9b4de74@o4507968654540800.ingest.de.sentry.io/4507968657227856',
  integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
});
