import React, { ChangeEvent, DragEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { FileTextIcon } from 'lucide-react';
import { ZodType } from 'zod';

import { closeModal } from '@/components/modals/modals.utils';
import { cn } from '@/lib/utils';

import { ModalBase } from '../../../components/modals/modals-base';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import Spinner from '../../../components/ui/spinner';
import { ACCEPTED_MEDIA_TYPES } from '../schema/upload-media';

export type UploadMediaModalType = {
  files: File[];
  fileName: string;
};

export interface UploadMediaModalProps {
  submitHandler: (data: UploadMediaModalType) => Promise<void>;
  acceptedType: string;
  acceptedMediaSchema: ZodType<any, any>;
}

export const UploadMediaModal: React.FC<UploadMediaModalProps> = ({
  submitHandler,
  acceptedMediaSchema,
  acceptedType,
}) => {
  const { t } = useTranslation();
  const [loading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<UploadMediaModalType>({
    resolver: zodResolver(acceptedMediaSchema),
    defaultValues: {
      files: [],
    },
  });

  const [, setDragActive] = useState(false);
  const files = watch('files');

  const fileInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);

    reset({ files: [...files, ...newFiles] });
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);

    const newFiles = Array.from(event.dataTransfer.files || []);

    reset({ files: [...files, ...newFiles] });
  };

  const removeFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    reset({ files: updatedFiles });
  };

  return (
    <ModalBase className="max-w-96">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-primary-lighter/40">
          <Spinner className="size-12 [&_*]:stroke-primary-dark" />
        </div>
      )}
      <form>
        <div
          className="flex w-full flex-col items-center justify-center space-y-3"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <label
            htmlFor="dropzone-file"
            className="my-4 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-text-secondary p-4 hover:bg-secondary"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <svg
                className="mb-4 size-8 text-text-secondary"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <>
                <p className="mb-1 text-center text-sm text-text-secondary">
                  {t('uploadDocumentText')}
                </p>
                <p className={cn('text-sm text-text-secondary', errors?.files && 'text-danger')}>
                  {acceptedType}
                </p>
              </>
            </div>
            <Input
              id="dropzone-file"
              type="file"
              accept={acceptedType}
              className="hidden"
              multiple
              {...register('files', { onChange: fileInputChangeHandler })}
            />
          </label>
        </div>
        {files && files.length > 0 && (
          <div className="grid max-h-96 grid-cols-3 gap-2 overflow-y-scroll">
            {files?.map((file, index) => (
              <div key={index} className="relative h-28">
                {file.type === ACCEPTED_MEDIA_TYPES.Pdf ? (
                  <div className="flex size-full items-center justify-center text-primary-light">
                    <FileTextIcon size={80} />
                  </div>
                ) : (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${file.name}`}
                    className="size-full rounded object-cover"
                  />
                )}
                <button
                  type="button"
                  className="absolute right-0 top-0 rounded-full text-text-secondary"
                  onClick={() => removeFile(index)}
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="mt-8 grid grid-cols-2 gap-4">
          <Button
            onClick={() => {
              closeModal();
            }}
            type="button"
            variant="outline"
            className="border-red-500 text-red-500"
          >
            {t('cancel')}
          </Button>
          <Button onClick={handleSubmit(submitHandler)}>{t('submit')}</Button>
        </div>
      </form>
    </ModalBase>
  );
};
