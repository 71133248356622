import { z } from 'zod';

export const ACCEPTED_DIGITAL_TWIN_FILE_TYPE = ['pdf'];

export const ACCEPTED_IMAGE_INPUT_TYPE = ACCEPTED_DIGITAL_TWIN_FILE_TYPE.map(
  (type) => `.${type}`
).join(',');

export const uploadDigitalTwinsSchema = () => {
  return z.object({
    name: z.string(),
    source: z.string(),
    file: z.instanceof(File).refine(
      (file: File) => {
        const [type] = file.name.split('.').reverse();
        return ACCEPTED_DIGITAL_TWIN_FILE_TYPE.includes(type);
      },
      {
        message: ACCEPTED_IMAGE_INPUT_TYPE,
      }
    ),
  });
};

export type SchemaType = z.infer<ReturnType<typeof uploadDigitalTwinsSchema>>;
