import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useQueryClient } from '@tanstack/react-query';

import { closeModal } from '@/components/modals/modals.utils';
import { Button } from '@/components/ui/button';
import { env } from '@/constants/env';
import { cn } from '@/lib/utils';

import { ModalBase } from '../../../components/modals/modals-base';
import Spinner from '../../../components/ui/spinner';
import { getEstateQueryOptions } from '../api/estate';
import { useUpdateEstateThumbnail } from '../api/estate-thumbnail';

export interface SelectThumbnailModalProps {
  estateId: string;
  images: any[];
}

export const SelectThumbnailModal: React.FC<SelectThumbnailModalProps> = ({ estateId, images }) => {
  const { t } = useTranslation();
  const [loading] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const queryClient = useQueryClient();

  const { mutateAsync } = useUpdateEstateThumbnail({
    mutationConfig: {
      onSuccess: () => {
        toast.success(t('update-estate-details-success'));
        queryClient.invalidateQueries({ queryKey: getEstateQueryOptions(estateId).queryKey });
      },
    },
  });

  const onImageClick = (id: string) => () => setSelectedImage(id);

  const onSubmit = async () => {
    await mutateAsync({ estateId, imageId: selectedImage });
    closeModal();
  };

  return (
    <ModalBase className="max-w-96">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-primary-lighter/40">
          <Spinner className="size-12 [&_*]:stroke-primary-dark" />
        </div>
      )}
      <p>{t('select-thumbnail-photo')}</p>
      <div className="grid max-h-96 max-w-lg grid-cols-2 gap-1 overflow-y-scroll rounded">
        {images.map((image) => (
          <div
            key={image.id}
            className={cn(
              'h-32 cursor-pointer overflow-hidden rounded',
              selectedImage === image.id && 'border-4 border-primary-light'
            )}
            onClick={onImageClick(image.id)}
          >
            <img
              className="size-full object-cover"
              src={`${env.API_URL}/images/${image.mediaId}?size=thumbnail200`}
              alt={image.fileName || ''}
            />
          </div>
        ))}
      </div>
      <div className="mt-3 grid grid-cols-2 gap-2">
        <Button variant="outline" onClick={closeModal}>
          {t('cancel')}
        </Button>
        <Button disabled={!selectedImage} onClick={onSubmit}>
          {t('submit')}
        </Button>
      </div>
    </ModalBase>
  );
};
