import { z } from 'zod';

import { i18n } from '@/lib/i18n';

export enum ACCEPTED_MEDIA_TYPES {
  Pdf = 'application/pdf',
  Jpeg = 'image/jpeg',
  Jpg = 'image/jpg',
  Png = 'image/png',
}

export const ACCEPTED_IMAGE_TYPE = ['png', 'jpeg', 'jpg'];
export const ACCEPTED_FLOORPLANS_TYPE = ['png', 'jpeg', 'jpg', 'pdf'];

export const ACCEPTED_IMAGE_INPUT_TYPE = ACCEPTED_IMAGE_TYPE.map((type) => `.${type}`).join(',');
export const ACCEPTED_FLOORPLANS_INPUT_TYPE = ACCEPTED_FLOORPLANS_TYPE.map(
  (type) => `.${type}`
).join(',');

const ACCEPTED_IMAGE_FILE_TYPES = ACCEPTED_IMAGE_TYPE.map((type) => `image/${type}`);

export const uploadImagesSchema = () => {
  return z.object({
    files: z.array(
      z.instanceof(File).refine((file: File) => ACCEPTED_IMAGE_FILE_TYPES.includes(file.type), {
        message: i18n.t('photoType'),
      })
    ),
  });
};

export const uploadLayoutsSchema = () => {
  return z.object({
    files: z.array(
      z
        .instanceof(File)
        .refine(
          (file: File) =>
            ACCEPTED_IMAGE_FILE_TYPES.includes(file.type) || file.type === ACCEPTED_MEDIA_TYPES.Pdf,
          {
            message: i18n.t('photoType'),
          }
        )
    ),
  });
};

export type SchemaType = typeof uploadImagesSchema | typeof uploadLayoutsSchema;
