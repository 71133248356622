import { useMutation, useQueryClient } from '@tanstack/react-query';

import { api, extractData } from '@/lib/treaty';

import { getAuthenticatedQueryOptions } from './authenticated';

const deleteCookies = () => {
  return extractData(api.authcookie.delete());
};

export const createCookies = (idToken: string) => {
  return api.authcookie.get({ headers: { Authorization: idToken } });
};

export const useCreateAuthCookies = () => {
  const queryClient = useQueryClient();

  const createAuthCookie = useMutation({
    mutationFn: createCookies,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: getAuthenticatedQueryOptions().queryKey });
    },
  });

  return { createAuthCookie };
};

export const useDeleteAuthCookies = () => {
  const queryClient = useQueryClient();

  const deleteAuthCookie = useMutation({
    mutationFn: deleteCookies,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: getAuthenticatedQueryOptions().queryKey });
    },
  });

  return { deleteAuthCookie };
};
