import { queryOptions, useMutation, useSuspenseQuery } from '@tanstack/react-query';

import { MutationConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

import { EstateSchemaType } from '../schemas/estate';

export type Estates = Awaited<ReturnType<typeof getEstates>>;

export type EstatesQueryFilters = Parameters<typeof api.estates.get>;

export type CreateEstateType = Parameters<typeof api.estates.post>;

type CreateEstateOptions = {
  mutationConfig?: MutationConfig<typeof createEstate>;
};

type EditEstateOptions = {
  mutationConfig?: MutationConfig<typeof editEstate>;
};

export const getEstates = (...args: EstatesQueryFilters) => {
  return extractData(api.estates.get(...args));
};

export const getEstatesOptions = (...args: EstatesQueryFilters) => ({
  queryKey: ['estates', ...args],
  queryFn: () => getEstates(...args),
});

export const getEstatesQueryOptions = (...args: EstatesQueryFilters) => {
  return queryOptions({
    queryKey: ['estates', ...args],
    queryFn: () => getEstates(...args),
  });
};

export const ensureEstatesQueryData = ({ context: { queryClient } }: LoaderProps) => {
  return queryClient.ensureQueryData(getEstatesQueryOptions({ query: {} }));
};

export const useEstates = (...args: EstatesQueryFilters) => {
  return useSuspenseQuery({ ...getEstatesQueryOptions(...args) });
};

const createEstate = (...args: CreateEstateType) => {
  return extractData(api.estates.post(...args));
};

export const useCreateEstate = ({ mutationConfig }: CreateEstateOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: createEstate,
  });
};

const editEstate = ({ id, ...args }: EstateSchemaType & { id: string }) => {
  return extractData(api.estates({ id }).put(args));
};

export const useEditEstate = ({ mutationConfig }: EditEstateOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: editEstate,
  });
};
