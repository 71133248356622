import { FC, useEffect, useState } from 'react';
import { RefCallBack, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { UseNavigateResult } from '@tanstack/react-router';
import { CameraOffIcon, House, SearchIcon } from 'lucide-react';

import { type Estates, getEstates } from '@/features/estates/api/estates';
import { EstateImage } from '@/features/estates-property/components/estate-image';
import useClickOutside from '@/hooks/use-click-outside';
import { useDebouncedCallback } from '@/hooks/use-debounced-callback';
import { cn } from '@/lib/utils';
import { getRenderValue } from '@/utils/getEmptyVisualValue';
import { getPriceWithCurrency } from '@/utils/getPriceWithCurrency';

import { Input } from '../ui/input';
import Spinner from '../ui/spinner';
import { closeModal } from './modals.utils';
import { ModalBase } from './modals-base';

export interface GlobalSearchModalProps {
  navigate: UseNavigateResult<string>;
}

export const GlobalSearchModal: FC<GlobalSearchModalProps> = ({ navigate }) => {
  const { t, i18n } = useTranslation();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [estatesHits, setEstatesHits] = useState<Estates>([]);
  const [loading, setLoading] = useState(true);

  const modalContainerRef = useClickOutside<HTMLDivElement>(closeModal, true);

  const { register, watch } = useForm<{ searchString: string }>({
    defaultValues: { searchString: '' },
  });

  const searchString = watch('searchString');

  const getDebouncedEstates = useDebouncedCallback(async () => {
    try {
      setLoading(true);
      const estates = await getEstates({
        query: {
          filters: {
            searchString,
          },
        },
      });
      setEstatesHits(estates);
    } finally {
      setLoading(false);
    }
  });

  const { ref, ...restInputProps } = register('searchString');

  const inputRefCallbackHandler: RefCallBack = (e) => {
    ref(e);
    setInputRef(e);
  };

  const onEstateHitClickHandler = (id: string) => () => {
    closeModal();
    navigate({ to: `/estates/${id}` });
  };

  useEffect(() => {
    getDebouncedEstates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  useEffect(() => {
    if (inputRef) inputRef.focus();
  }, [inputRef]);

  return (
    <ModalBase ref={modalContainerRef} overlayWrapperClassname="items-start" className="top-6">
      <Input
        {...restInputProps}
        ref={inputRefCallbackHandler}
        className="w-full"
        placeholder={t('search')}
        wrapperClassName="hidden sm:block"
        startIcon={<SearchIcon className="text-primary-light" />}
      />
      <div
        className={cn(
          'relative mt-4 grid h-screen max-h-96 w-screen max-w-screen-sm grid-cols-3 gap-2 overflow-y-scroll',
          estatesHits.length && 'grid grid-cols-3 gap-2'
        )}
      >
        {loading && !estatesHits.length && (
          <div className="absolute z-10 flex h-screen max-h-96 w-screen max-w-screen-sm flex-col-reverse items-center justify-center bg-background">
            <Spinner className="size-12 [&_*]:stroke-primary-dark" />
          </div>
        )}
        {estatesHits.length ? (
          estatesHits.map((estate) => (
            <div
              key={estate.id}
              className="cursor-pointer shadow-sm hover:shadow-md"
              onClick={onEstateHitClickHandler(estate.id)}
            >
              {!estate.thumbnail?.mediaId ? (
                <div
                  className={cn(
                    'flex aspect-square items-center justify-center overflow-hidden rounded rounded-b-none bg-secondary text-primary-light'
                  )}
                >
                  <CameraOffIcon size={35} />
                </div>
              ) : (
                <div className={cn('aspect-square overflow-hidden rounded rounded-b-none')}>
                  <EstateImage
                    {...(estate.thumbnail?.mediaId && { thumbnailId: estate.thumbnail?.mediaId })}
                  />
                </div>
              )}
              <div className="flex flex-col">
                <span className="flex-1 truncate text-md">{getRenderValue(estate.name)}</span>
                <div className="flex gap-2 text-sm">
                  <span className="truncate text-text-secondary">{t('rooms')}:</span>
                  <span className="truncate">
                    {getRenderValue(estate.estateAreasRooms?.totalNumberOfRooms)}
                  </span>
                </div>
                <div className="flex gap-2 text-sm">
                  <span className="truncate text-text-secondary">{t('price')}:</span>
                  <span className="truncate">
                    {getRenderValue(
                      getPriceWithCurrency(
                        estate.prices?.buyingPrice,
                        estate.prices?.currency,
                        i18n.language
                      )
                    )}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Trans
            t={t}
            i18nKey="no-estates-found"
            components={{
              label: <span className="font-medium text-primary-light" />,
              wrapper: (
                <div className="flex h-screen max-h-96 w-screen max-w-screen-sm flex-col-reverse items-center justify-center" />
              ),
              icon: (
                <House
                  viewBox="-1 0 25 25"
                  width="60"
                  height="60"
                  className="[&_*]:stroke-primary-light"
                />
              ),
            }}
          />
        )}
      </div>
    </ModalBase>
  );
};
