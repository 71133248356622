export function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
  return undefined;
}

export function setCookie(
  name: string,
  value: string,
  options: {
    maxAge?: number;
    secure?: boolean;
    sameSite?: 'Strict' | 'Lax' | 'None';
    path?: string;
  } = {}
) {
  const { maxAge = 7 * 24 * 60 * 60, secure = true, sameSite = 'Lax', path = '/' } = options;
  let cookieString = `${name}=${value}; max-age=${maxAge}; path=${path}; samesite=${sameSite.toLowerCase()}`;

  // safari doesn't allow secure cookies on localhost
  if (secure && import.meta.env.MODE !== 'development') {
    cookieString += '; secure';
  }
  document.cookie = cookieString;
}

export function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
