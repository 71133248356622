import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { Estate } from '@/features/estates-property/api/estate';
import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

export type DocumentsData = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.estates>['documents']['get']>>['data']
>;

export type Document = DocumentsData['documents'][number];

type DocumentsQueryOptions = {
  queryConfig?: QueryConfig<typeof getDocuments>;
  id: Estate['id'];
  order: 'asc' | 'desc';
};

const getDocuments = (id: Estate['id'], order: 'asc' | 'desc') => {
  return extractData(
    api.estates({ id }).documents.get({ query: { sorting: { field: 'updatedAt', order } } })
  );
};

export const getDocumentsQueryOptions = (id: Estate['id'], order: 'asc' | 'desc') => {
  return queryOptions({
    queryKey: ['documents', id, order],
    queryFn: () => getDocuments(id, order),
  });
};

export const getDocumentOptions = (id: Estate['id'], order: 'asc' | 'desc') => ({
  queryKey: ['documents', id, order],
  queryFn: () => getDocuments(id, order),
});

export const ensureDocumentQueryData = ({ context: { queryClient }, params }: LoaderProps) => {
  return queryClient.ensureQueryData(getDocumentsQueryOptions(params.estateId, 'asc'));
};

export const useDocuments = ({ queryConfig, id, order }: DocumentsQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getDocumentsQueryOptions(id, order) });
};
