import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useNavigate } from '@tanstack/react-router';
import {
  ArrowLeftFromLine,
  BellIcon,
  CirclePlus,
  LogOutIcon,
  MenuIcon,
  SearchIcon,
  UserIcon,
} from 'lucide-react';

import { useMe } from '@/api/me';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Logo } from '@/components/ui/logo';
import { useLogOut } from '@/features/auth/api/logout';
import { getAvatarFallback } from '@/utils/avatar';

import { openModal } from '../modals/modals.utils';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { Button } from '../ui/button';
import { Input } from '../ui/input';

type HeaderProps = {
  onClick: Dispatch<SetStateAction<boolean>>;
  isVisible: boolean;
};

export const Header = ({ onClick, isVisible }: HeaderProps) => {
  const { logout } = useLogOut();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    await logout();
    // this seems to be the most reliable way to start the user journey from scratch
    window.location.reload();
  };

  const me = useMe();

  const onClickHandler = () => onClick((prev) => !prev);

  const onSearchInputFocus = () => openModal('global-search', { navigate });

  const onAddEstateButtonClick = () => {
    openModal('create-estate', { navigate });
  };

  return (
    <header className="flex h-[108px] items-center justify-between rounded-sm border-b border-gray-200 bg-primary-lighter p-4 lg:py-6 lg:pl-10 lg:pr-14">
      <Button className="border-0 lg:hidden" variant="outline" size="sm" onClick={onClickHandler}>
        {isVisible ? <ArrowLeftFromLine /> : <MenuIcon />}
      </Button>
      <Logo />
      <div className="ml-2 flex w-full max-w-screen-sm items-center gap-2">
        <Input
          size="lg"
          placeholder={t('search')}
          wrapperClassName="hidden sm:block"
          onFocus={onSearchInputFocus}
          startIcon={<SearchIcon className="text-primary-light" />}
        />
        <CirclePlus
          onClick={onAddEstateButtonClick}
          className="mx-auto size-12 cursor-pointer text-primary-light sm:mx-0"
        />
      </div>
      <div className="flex items-center gap-[21px]">
        <BellIcon size={28} />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Avatar className="size-14 cursor-pointer">
              <AvatarImage
                src={`/images/${me.data.images?.mediaId}?size=thumbnail100`}
                alt="avatar"
              />
              <AvatarFallback>{getAvatarFallback(me.data)}</AvatarFallback>
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-44">
            <DropdownMenuItem asChild>
              <Link to="/profile">
                <UserIcon />
                Profile
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <Link onClick={handleLogOut} preload={false}>
                <LogOutIcon />
                Logout
              </Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};
