import { useTranslation } from 'react-i18next';

import { createFileRoute } from '@tanstack/react-router';

const Component = () => {
  const { t } = useTranslation();

  return <div>{t('about.title')}</div>;
};

export const Route = createFileRoute('/_authenticated/_sidebar-layout/about')({
  component: Component,
});
