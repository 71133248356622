import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

export type Estate = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.estates>['all']['get']>>['data']
>;

type EstateQueryOptions = {
  queryConfig?: QueryConfig<typeof getEstate>;
  id: Estate['id'];
};

const getEstate = (id: Estate['id']) => {
  return extractData(api.estates({ id: id }).all.get());
};

export const getEstateOptions = (id: Estate['id']) => ({
  queryKey: ['estates', id],
  queryFn: () => getEstate(id),
});

export const getEstateQueryOptions = (id: Estate['id']) => {
  return queryOptions(getEstateOptions(id));
};

export const ensureEstateQueryData = ({ context: { queryClient }, params }: LoaderProps) => {
  return queryClient.ensureQueryData(getEstateQueryOptions(params.estateId));
};

export const useEstate = ({ queryConfig, id }: EstateQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getEstateQueryOptions(id) });
};
