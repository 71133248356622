import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { ensureEstatesQueryData } from '@/features/estates/api/estates';

export const Route = createFileRoute('/_authenticated/_sidebar-layout/estates/me')({
  validateSearch: zodSearchValidator(
    z.object({
      view: fallback(z.enum(['grid', 'list']), 'grid'),
      search: fallback(z.string().optional(), ''),
      filterOwn: fallback(z.boolean(), true),
    })
  ),
  loader: ensureEstatesQueryData,
});
