import { useMutation } from '@tanstack/react-query';
import { signOut } from 'firebase/auth';

import { auth } from '@/lib/firebase';
import { deleteCookie } from '@/utils/cookies';

import { useDeleteAuthCookies } from './auth-cookies';

export const useLogOut = () => {
  const { deleteAuthCookie } = useDeleteAuthCookies();

  const signOutMutation = useMutation({
    mutationFn: () => signOut(auth),
  });

  const logout = async () => {
    deleteCookie('rt');
    await deleteAuthCookie.mutateAsync();
    await signOutMutation.mutateAsync();
  };

  return { logout };
};
