import { createFileRoute, redirect } from '@tanstack/react-router';

import { ensureMeQueryData, getMeQueryOptions } from '@/api/me';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async ({ context, location }) => {
    if (!context.authenticated) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }

    try {
      const user = await context.queryClient.fetchQuery(getMeQueryOptions());
      if (!user) {
        throw new Error('User not found');
      }
    } catch (_error) {
      console.log(_error);
      throw redirect({
        to: '/complete-profile',
      });
    }
  },
  loader: ensureMeQueryData,
});
