import { getCookie } from '@/utils/cookies';

import { refreshToken } from './firebase';

let refreshPromise: Promise<void> | null = null;

export const customFetch = async (input: RequestInfo | URL, init?: RequestInit) => {
  const response = await fetch(input, { ...init, credentials: 'include' });

  if (response.status === 401) {
    if (refreshPromise) {
      await refreshPromise;
      return fetch(input, { ...init, credentials: 'include' });
    }

    try {
      const rt = getCookie('rt');
      if (!rt) {
        throw new Error('No refresh token found');
      }
      refreshPromise = refreshToken(rt);
      await refreshPromise;

      return fetch(input, { ...init, credentials: 'include' });
    } finally {
      refreshPromise = null;
    }
  }

  return response;
};
