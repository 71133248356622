import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import translations from '@/assets/translations.json';
import { languages } from '@/constants/languages';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  supportedLngs: languages,
  fallbackLng: false,
  debug: true,
  resources: translations,
});

export { i18n };
