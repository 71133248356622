import * as React from 'react';
import MapBoxMap, { FullscreenControl, Marker, NavigationControl, Popup } from 'react-map-gl';

import { env } from '@/constants/env';
import { cn } from '@/lib/utils';

const MAP_BOX_TOKEN = env.MAP_BOX_API_TOKEN;

interface MapProps {
  longitude?: number | null;
  latitude?: number | null;
  zoom?: number;
  bearing?: number;
  pitch?: number;
  popupInfo?: React.ReactNode;
}

const Icon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="29"
    height="47"
    viewBox="0 0 29 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 0C6.48929 0 0 6.57203 0 14.6849C0 29.3698 14.5 47 14.5 47C14.5 47 29 29.3802 29 14.6849C29 6.57203 22.5107 0 14.5 0ZM14.5 23.6782C9.59422 23.6782 5.61991 19.6532 5.61991 14.6849C5.61991 9.71655 9.59422 5.69157 14.5 5.69157C19.4058 5.69157 23.3801 9.71655 23.3801 14.6849C23.3801 19.6532 19.4058 23.6782 14.5 23.6782ZM8.88009 14.6849C8.88009 17.8294 11.3951 20.3764 14.5 20.3764C17.6049 20.3764 20.1199 17.8294 20.1199 14.6849C20.1199 11.5404 17.6049 8.99331 14.5 8.99331C11.3951 8.99331 8.88009 11.5404 8.88009 14.6849Z"
      fill="#07B1BE"
    />
  </svg>
);

export const Map: React.FC<MapProps> = ({
  zoom: propsZoom = 14,
  bearing = 0,
  pitch = 0,
  popupInfo,
  ...positionProps
}) => {
  const [openedPlacePopup, setOpenedPlacePopup] = React.useState(false);

  const showPopupInfo = () => {
    if (popupInfo) setOpenedPlacePopup(true);
  };
  const closePopupInfo = () => setOpenedPlacePopup(true);

  return (
    <div
      className={cn(
        'size-full',
        '[&_.mapboxgl-ctrl-group]:top-[50%] [&_.mapboxgl-ctrl-group]:flex [&_.mapboxgl-ctrl-group]:flex-col [&_.mapboxgl-ctrl-group]:gap-2 [&_.mapboxgl-ctrl-group]:bg-transparent [&_.mapboxgl-ctrl-group]:shadow-none',
        '[&_.mapboxgl-ctrl-shrink]:size-8 [&_.mapboxgl-ctrl-shrink]:!rounded-sm [&_.mapboxgl-ctrl-shrink]:bg-primary-light',
        '[&_.mapboxgl-ctrl-fullscreen]:size-8 [&_.mapboxgl-ctrl-fullscreen]:!rounded-sm [&_.mapboxgl-ctrl-fullscreen]:bg-primary-light',
        '[&_.mapboxgl-ctrl-zoom-in]:size-8 [&_.mapboxgl-ctrl-zoom-in]:!rounded-sm [&_.mapboxgl-ctrl-zoom-in]:bg-primary-light',
        '[&_.mapboxgl-ctrl-zoom-out]:size-8 [&_.mapboxgl-ctrl-zoom-out]:!rounded-sm [&_.mapboxgl-ctrl-zoom-out]:bg-primary-light',
        '[&_.mapboxgl-ctrl-bottom-right]:hidden [&_.mapboxgl-ctrl-compass]:hidden [&_.mapboxgl-ctrl-scale]:hidden [&_a.mapboxgl-ctrl-logo]:hidden'
      )}
    >
      {!isNaN(Number(positionProps.latitude)) && !isNaN(Number(positionProps.longitude)) && (
        <MapBoxMap
          initialViewState={{
            ...positionProps,
            zoom: propsZoom,
            bearing,
            pitch,
          }}
          latitude={positionProps.latitude}
          longitude={positionProps.longitude}
          mapStyle="mapbox://styles/mapbox/light-v9"
          mapboxAccessToken={MAP_BOX_TOKEN}
        >
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <Marker {...positionProps} anchor="bottom" onClick={showPopupInfo}>
            <Icon className={cn(popupInfo && 'cursor-pointer')} />
          </Marker>
          {openedPlacePopup && (
            <Popup {...positionProps} anchor="top" onClose={closePopupInfo}>
              {popupInfo}
            </Popup>
          )}
        </MapBoxMap>
      )}
    </div>
  );
};
