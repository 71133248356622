import { EventEmitter } from 'events';

import { ModalsCallArgs } from './modals-root.types';

export const EMITTER_EVENT = '[MODAL]';

export const eventEmitter = new EventEmitter();

export const openModal = (...args: ModalsCallArgs) => eventEmitter.emit(EMITTER_EVENT, ...args);

export const closeModal = () => eventEmitter.emit(EMITTER_EVENT, '');
