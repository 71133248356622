import React from 'react';

import { Link } from '@tanstack/react-router';
import { cva, VariantProps } from 'class-variance-authority';

import { env } from '@/constants/env';
import { cn } from '@/lib/utils';

const logoVariants = cva('flex h-[59px] items-center justify-center', {
  variants: {
    size: {
      sm: 'h-[49px]',
      md: 'h-[59px]',
      lg: 'h-[69]',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type LogoProps = React.ComponentProps<'div'> & VariantProps<typeof logoVariants>;

export const Logo = ({ size, className, ...props }: LogoProps) => {
  return (
    <Link to="/">
      <div className={cn(logoVariants({ className, size }))} {...props}>
        <img src={env.LOGO_URL} className="size-full shrink-0 object-contain" alt="logo" />
      </div>
    </Link>
  );
};
