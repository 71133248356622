import { useState } from 'react';

import { CameraOffIcon } from 'lucide-react';

import { env } from '@/constants/env';
import { cn } from '@/lib/utils';

type EstateImageProps = {
  thumbnailId?: string | null;
  isRounded?: boolean;
  className?: string;
};

export const EstateImage = ({
  thumbnailId = 'default-estate-thumbnail',
  isRounded,
  className,
}: EstateImageProps) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      <div
        className={cn(
          'flex aspect-square size-full items-center justify-center overflow-hidden bg-secondary text-primary-light',
          isRounded && 'rounded-md',
          loaded && 'hidden',
          className
        )}
      >
        <CameraOffIcon size={35} />
      </div>
      <div
        className={cn(
          'aspect-square overflow-hidden',
          isRounded && 'rounded-md',
          !loaded && 'hidden',
          className
        )}
      >
        <img
          className="size-full object-cover"
          src={`${env.API_URL}/images/${thumbnailId}?size=thumbnail500`}
          {...(thumbnailId && { alt: thumbnailId })}
          onLoad={() => setLoaded(true)}
        />
      </div>
    </>
  );
};
