import { MeUser } from '@/api/me';

export const getAvatarFallback = ({ firstName, lastName, email }: MeUser) => {
  const getInitial = (str?: string | null) => {
    return str?.trim().charAt(0).toUpperCase();
  };

  const initials = [getInitial(firstName), getInitial(lastName)].filter(Boolean);

  if (initials.length > 0) {
    return initials.join('');
  }

  return getInitial(email?.split('@')[0]) || 'U';
};
