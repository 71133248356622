import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { useAuth } from '@/components/providers/use-auth';
import { Logo } from '@/components/ui/logo';

const fallback = '/' as const;

const Component = () => {
  const { t } = useTranslation();
  const { data, isPending } = useAuth();
  const search = Route.useSearch();
  const navigate = Route.useNavigate();

  useEffect(() => {
    if (data && !isPending) {
      navigate({ to: search.redirect || fallback });
    }
  }, [data, navigate, search, isPending]);

  if (data) return null;

  return (
    <div className="flex h-screen flex-col md:flex-row">
      {/* Right side - Image and Branding (Displayed on top for small screens) */}
      <div className="order-1 flex w-full flex-col items-center justify-center space-y-8 bg-[url('/login-bg.jpg')] bg-cover bg-center p-8 md:order-2 md:w-1/2">
        <div className="w-2/3">
          <Logo size="lg" />
        </div>
        <div className="text-center">
          <p className="px-10 text-3xl font-bold text-white">{t('login-screen-hero-text')}</p>
        </div>
      </div>

      {/* Left side - Login Form */}
      <div className="order-2 flex w-full flex-col justify-center p-8 md:order-1 md:w-1/2">
        <div className="mx-auto w-full max-w-md">
          <h1 className="mb-2 text-2xl font-bold">{t('welcome-at-connect')}</h1>
          <p className="mb-8 text-gray-600">{t('immo-and-3d')}</p>
          <Outlet />
        </div>

        <footer className="mt-12 text-center text-sm text-gray-500">
          <a href="#" className="mx-2 hover:underline">
            Impressum
          </a>
          <a href="#" className="mx-2 hover:underline">
            Datenschutz
          </a>
          <a href="#" className="mx-2 hover:underline">
            Über Uns
          </a>
        </footer>
      </div>
    </div>
  );
};

export const Route = createFileRoute('/_auth-layout')({
  validateSearch: z.object({
    redirect: z.string().optional().catch(''),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.authenticated) {
      throw redirect({ to: search.redirect || fallback });
    }
  },
  component: Component,
});
