import React from 'react';
import { useTranslation } from 'react-i18next';

import { CloudDownloadIcon, FolderOpenIcon, MapPinIcon } from 'lucide-react';

import { cn } from '@/lib/utils';

import { NavbarLink } from '../ui/navbar-link';

export type DashboardNavProps = React.ComponentProps<'nav'>;

export const DashboardNav = ({ className, ...props }: DashboardNavProps) => {
  const { t } = useTranslation();

  return (
    <nav className={cn('flex justify-between gap-1', className)} {...props}>
      <NavbarLink
        title={t('objectDashboard')}
        icon={<MapPinIcon />}
        to="/estates/$estateId"
        activeOptions={{
          exact: true,
        }}
      >
        {t('objectDashboard')}
      </NavbarLink>
      <NavbarLink
        title={t('mediaDatabase')}
        to="/estates/$estateId/media"
        icon={<FolderOpenIcon />}
      >
        {t('mediaDatabase')}
      </NavbarLink>
      <NavbarLink
        title={t('documents')}
        to="/estates/$estateId/documents"
        icon={<CloudDownloadIcon />}
      >
        {t('documents')}
      </NavbarLink>
    </nav>
  );
};
