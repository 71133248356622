import React from 'react';

import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const inputVariants = cva(
  'flex w-full rounded-xs bg-primary-lighter shadow-sm transition-colors focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 [&_svg]:shrink-0',
  {
    variants: {
      color: {
        primary:
          '[--input-color:hsla(var(--primary-color))] [--input-focus-color:hsla(var(--primary-color-light))]',
        'primary-light':
          '[--input-color:hsla(var(--primary-color-light))] [--input-focus-color:hsla(var(--primary-color))]',
      },
      variant: {
        default:
          'border-[--input-color] text-[--input-color] placeholder:font-extralight placeholder:text-[--input-color] focus-visible:border-[--input-focus-color]',
      },
      size: {
        xs: 'h-[24px] rounded-md px-[10px] text-sm font-medium',
        sm: 'h-[39px] rounded-md p-2 text-sm font-medium placeholder:font-normal',
        md: 'h-[49px] border-2 px-4 text-sm [&_svg]:size-[28px]',
        lg: 'h-[57px] border px-6 text-md [&_svg]:size-[38px]',
      },
    },
    defaultVariants: {
      color: 'primary-light',
      variant: 'default',
      size: 'md',
    },
  }
);

export type InputProps = Omit<React.ComponentProps<'input'>, 'size'> &
  VariantProps<typeof inputVariants> & {
    wrapperClassName?: string;
    label?: string;
    errors?: string[];
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
  };

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      wrapperClassName,
      className,
      type,
      size,
      variant,
      color,
      label,
      errors,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => (
    <label className={cn('flex w-full cursor-text flex-col gap-[10px]', wrapperClassName)}>
      {label && <span className="select-none text-xs text-text-secondary">{label}</span>}
      <div className="relative flex w-full flex-row items-center">
        {startIcon && (
          <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
            {startIcon}
          </div>
        )}
        <input
          ref={ref}
          type={type}
          className={inputVariants({
            className: cn(className, startIcon && 'pl-12', endIcon && 'pr-12'),
            size,
            variant,
            color,
          })}
          {...props}
        />
        {endIcon && (
          <div className="pointer-events-none absolute inset-y-0 end-0 flex items-center ps-3">
            {endIcon}
          </div>
        )}
      </div>
      {errors?.map((error, index) => (
        <p
          key={error}
          role="alert"
          className="select-none overflow-auto text-xs text-danger opacity-0 motion-safe:animate-slide-in"
          style={{ '--delay': index * 0.1 + 's' } as React.CSSProperties}
        >
          {error}
        </p>
      ))}
    </label>
  )
);

Input.displayName = 'Input';

export { Input };
