import { useMutation } from '@tanstack/react-query';

import { MutationConfig } from '@/lib/react-query';
import { api, extractData } from '@/lib/treaty';

type UploadDigitalTwins = {
  mutationConfig?: MutationConfig<typeof uploadDigitalTwins>;
};

export type UploadDigitalTwinsType = {
  id: string;
  name: string;
  file: File;
  source: string;
};

const uploadDigitalTwins = ({ id, ...body }: UploadDigitalTwinsType) => {
  return extractData(api.estates({ id })['digital-twins'].post(body));
};

export const useUploadDigitalTwins = ({ mutationConfig }: UploadDigitalTwins = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: uploadDigitalTwins,
  });
};
