import { useState } from 'react';

import { Header } from '@/components/layout/header';
import { Sidebar } from '@/components/layout/sidebar';

export const MainLayout = ({ children }: React.PropsWithChildren) => {
  const [isVisible, setVisible] = useState(false);

  const closeMobileSidebarMenu = () => setVisible(false);

  return (
    <div className="relative flex h-screen flex-col bg-background-lighter">
      <Header isVisible={isVisible} onClick={setVisible} />
      <div className="flex h-[calc(100vh-108px)] flex-1 bg-background-lighter px-2 pt-4 lg:pl-[46px] lg:pt-[41px]">
        <Sidebar closeMobileSidebarMenu={closeMobileSidebarMenu} isVisible={isVisible} />
        <div
          onClick={closeMobileSidebarMenu}
          className={`${isVisible ? 'max-md:absolute max-md:bottom-0 max-md:z-30 max-md:h-[calc(100vh-107px)] max-md:w-full max-md:bg-black max-md:opacity-50' : 'hidden'}`}
        />
        <main className="flex-1 overflow-auto px-4 lg:pl-[27px] lg:pr-[46px]">{children}</main>
      </div>
    </div>
  );
};
