import React, { ChangeEvent, DragEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';

import { closeModal } from '@/components/modals/modals.utils';
import { getDocumentOptions } from '@/features/estate-document/api/document';
import { useUploadDocument } from '@/features/estate-document/api/upload-document';

import { ModalBase } from '../../../components/modals/modals-base';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import Spinner from '../../../components/ui/spinner';
import { ACCEPTED_INPUT_TYPE, uploadDocumentSchema } from '../schema/document-schema';

type UploadDocumentType = {
  file: File;
  fileName: string;
};

export interface UploadDocumentsModalProps {
  estateId: string;
  order: 'asc' | 'desc';
}

export const UploadDocumentsModal: React.FC<UploadDocumentsModalProps> = ({ estateId, order }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<UploadDocumentType>({
    resolver: zodResolver(uploadDocumentSchema()),
  });
  const queryClient = useQueryClient();

  const { mutate: uploadDocument } = useUploadDocument({
    mutationConfig: {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: getDocumentOptions(estateId, order).queryKey }),
    },
  });

  const [, setDragActive] = useState(false);
  const file = watch('file');

  const fileInputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const fileName = file?.name.replace(/\.[^/.]+$/, '');
    reset({ file, fileName });
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);

    const file = event.dataTransfer.files?.[0];
    const fileName = file?.name.replace(/\.[^/.]+$/, '');
    reset({ file, fileName });
  };

  const submitHandler = async (data: UploadDocumentType) => {
    try {
      setLoading(true);
      await uploadDocument({ document: data.file, name: data.fileName, estateId });
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase className="max-w-96">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-primary-lighter/40">
          <Spinner className="size-12 [&_*]:stroke-primary-dark" />
        </div>
      )}
      <form>
        <div
          className="flex w-full flex-col items-center justify-center space-y-3"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <label
            htmlFor="dropzone-file"
            className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-text-secondary p-4 hover:bg-secondary"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <svg
                className="mb-4 size-8 text-text-secondary"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              {!file ? (
                <>
                  <p className="mb-1 text-center text-sm text-text-secondary">
                    {t('uploadDocumentText')}
                  </p>
                  <p className="text-sm text-text-secondary">{t('pdf')}</p>
                </>
              ) : (
                <p className="text-sm text-text-secondary">
                  {t('uploadedDocument')}: <span className="font-semibold">{file?.name}</span>
                </p>
              )}
              <p className="text-sm text-danger">{errors.file?.message}</p>
            </div>
            <Input
              id="dropzone-file"
              type="file"
              accept={`.${ACCEPTED_INPUT_TYPE}`}
              className="hidden"
              {...register('file', { onChange: fileInputChangeHandler })}
            />
          </label>
          <Input {...register('fileName')} label={t('fileName')} className="w-full" />
        </div>
        <div className="mt-8 grid grid-cols-2 gap-4">
          <Button
            onClick={() => {
              closeModal();
            }}
            type="button"
            variant="outline"
            className="border-red-500 text-red-500"
          >
            {t('cancel')}
          </Button>
          <Button onClick={handleSubmit(submitHandler)}>{t('submit')}</Button>
        </div>
      </form>
    </ModalBase>
  );
};
