import React, { ComponentProps, forwardRef } from 'react';

import { type PortalProps, Root } from '@radix-ui/react-portal';

import { cn } from '@/lib/utils';

interface ModalBaseProps extends PortalProps, Pick<ComponentProps<'div'>, 'ref'> {
  overlayWrapperClassname?: string;
}

export const ModalBase: React.FC<ModalBaseProps> = forwardRef<HTMLDivElement, ModalBaseProps>(
  ({ className, overlayWrapperClassname, children, ...props }, ref) => (
    <Root
      {...props}
      className={cn(
        'fixed inset-0 z-50 flex animate-fade-in items-center justify-center rounded-[20px] bg-gray-500/20',
        overlayWrapperClassname
      )}
    >
      <div ref={ref} className={cn('relative rounded bg-primary-lighter p-8', className)}>
        {children}
      </div>
    </Root>
  )
);
