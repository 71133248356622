import React from 'react';
import ReactDOM from 'react-dom/client';

import { PostHogProvider } from 'posthog-js/react';

import '@/lib/i18n';
import '@/lib/sentry';
import { RouterProvider } from '@/components/providers/router';
import { ThemeProvider } from '@/components/providers/theme';
import { router } from '@/lib/router';

import { ModalsRoot } from './components/modals/modals-root';
import { QueryProvider } from './components/providers/query';

import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element is not found');
}

if (rootElement.innerHTML) {
  throw new Error('Root element contains some elements');
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <QueryProvider>
        <PostHogProvider>
          <RouterProvider />
          <ModalsRoot />
        </PostHogProvider>
      </QueryProvider>
    </ThemeProvider>
  </React.StrictMode>
);
