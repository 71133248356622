import React, { ChangeEvent, DragEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';

import { closeModal } from '@/components/modals/modals.utils';
import { ModalBase } from '@/components/modals/modals-base';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Select } from '@/components/ui/select';
import Spinner from '@/components/ui/spinner';

import { getDigitalTwinsQueryOptions } from '../../api/get-digital-twins';
import { useUploadDigitalTwins } from '../../api/upload-digital-twins';
import {
  ACCEPTED_DIGITAL_TWIN_FILE_TYPE,
  type SchemaType,
  uploadDigitalTwinsSchema,
} from '../../schema/upload-digital-twins';

export interface UploadDigitalTwinsModalProps {
  estateId: string;
}

export const UploadDigitalTwinsModal: React.FC<UploadDigitalTwinsModalProps> = ({ estateId }) => {
  const { t } = useTranslation();
  const schema = uploadDigitalTwinsSchema();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      source: '',
    },
  });
  const queryClient = useQueryClient();

  const { mutate: uploadDocument } = useUploadDigitalTwins({
    mutationConfig: {
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: getDigitalTwinsQueryOptions(estateId).queryKey,
        }),
    },
  });

  const [, setDragActive] = useState(false);
  const file = watch('file');

  const fileInputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    reset({ file, name: file?.name });
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);

    const file = event.dataTransfer.files?.[0];
    const name = file?.name.replace(/\.[^/.]+$/, '');
    reset({ file, name });
  };

  const submitHandler = async (data: SchemaType) => {
    try {
      setLoading(true);
      await uploadDocument({ id: estateId, ...data });
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  const sourceOptions = [
    {
      id: 'editor',
      title: t('editor'),
    },
    {
      id: 'apple',
      title: t('apple'),
    },
    {
      id: 'paper2pixel',
      title: t('paper2pixel'),
    },
  ];

  return (
    <ModalBase className="max-w-96">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-primary-lighter/40">
          <Spinner className="size-12 [&_*]:stroke-primary-dark" />
        </div>
      )}
      <form onSubmit={handleSubmit(submitHandler)}>
        <div
          className="flex w-full flex-col items-center justify-center space-y-3"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <label
            htmlFor="dropzone-file"
            className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-text-secondary p-4 hover:bg-secondary"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <svg
                className="mb-4 size-8 text-text-secondary"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              {!file ? (
                <>
                  <p className="mb-1 text-center text-sm text-text-secondary">
                    {t('uploadDocumentText')}
                  </p>
                  <p className="text-sm text-text-secondary">{t('pdf')}</p>
                </>
              ) : (
                <p className="text-sm text-text-secondary">
                  {t('uploadedDocument')}: <span className="font-semibold">{file?.name}</span>
                </p>
              )}
              <p className="text-sm text-danger">{errors.file?.message}</p>
            </div>
            <Input
              id="dropzone-file"
              type="file"
              accept={`.${ACCEPTED_DIGITAL_TWIN_FILE_TYPE}`}
              className="hidden"
              {...register('file', { onChange: fileInputChangeHandler })}
            />
          </label>

          <Controller
            control={control}
            name="name"
            render={({ field }) => <Input {...field} label={t('fileName')} className="w-full" />}
          />

          <Controller
            control={control}
            name="source"
            render={({ field }) => (
              <Select {...field} label={t('source')} options={sourceOptions} />
            )}
          />
        </div>
        <div className="mt-8 grid grid-cols-2 gap-4">
          <Button
            onClick={closeModal}
            type="button"
            variant="outline"
            className="border-red-500 text-red-500"
          >
            {t('cancel')}
          </Button>
          <Button type="submit">{t('submit')}</Button>
        </div>
      </form>
    </ModalBase>
  );
};
